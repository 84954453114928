module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  SOCKET_URL: process.env.REACT_APP_API_CP_DOMAIN,
  BACKEND_CP_URL: process.env.REACT_APP_SOCKET_URL,
  MAX_UNAPPROVED_ACCOUNTS: 3,
  enableFX: process.env.REACT_APP_ENABLE_FX === "true" || true,
  enableCrypto: process.env.REACT_APP_ENABLE_CRYPTO === "true" || false,
  enableCryptoWallets: process.env.REACT_APP_ENABLE_CRYPTO_WALLETS === "true" || false,
  defaultPortal: process.env.REACT_APP_DEFAULT_PORTAL || "FOREX",
  companyCryptoDeposit: process.env.REACT_APP_COMPANY_CRYPTO_DEPOSIT === "true" || false,
  COMPANY_WALLETS: [
    {
      name:"USDT",
      networks: [
        {
          name: "TRC20",
          address: "TZ3fThzqSyadYyeDzMLczTo4EdMvrSBjUi",
          tag: "TRC20",
        }
      ],
    }
  ],
  ENABLE_DARK_MODE: true,
  ENABLE_GLOBAL_SEARCH: false,
  LEVERAGES: [1, 50, 100],
  PLATFORMS: [
    // {
    //   name: "Desktop Trader - MT5",
    //   logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
    //   image: "https://www.metatrader5.com/i/main/metatrader-5-windows.jpg",
    //   isLocalImg: false,
    //   downloadLink: "https://download.metatrader.com/cdn/web/mki.investment.ltd/mt5/mkiinvestmentltd5setup.exe",
    // },
    // {
    //   name: "Android Trader - MT5",
    //   logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
    //   image: "https://i.ytimg.com/vi/MTLbJ5lSW9M/maxresdefault.jpg",
    //   isLocalImg: false,
    //   downloadLink: "https://download.metatrader.com/cdn/mobile/mt5/android?server=MKIInvestmentLtd-Server",
    // },
    // {
    //   name: "IOS Trader - MT5",
    //   logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
    //   image: "https://www.ifcmarkets.ae/images/front/platforms/MT4-MT5-mobile-ios.png",
    //   isLocalImg: false,
    //   downloadLink: "https://download.metatrader.com/cdn/mobile/mt5/ios?server=MKIInvestmentLtd-Server",
    // },
    {
      name: "InfinanceFx Trade Desktop Trader",
      // logo: "https://portal.slingstrade.com/assets/logo192.png",
      logo: "/img/platforms/STMockup.jpg",
      image: "/img/platforms/STMockup.jpg",
      isLocalImg: false,
      downloadLink: "https://www.hybridsolutions.com/downloads/InfinanceFxbo.exe",
    },
    {
      name: "InfinanceFx Trade Android Trader",
      // logo: "https://portal.slingstrade.com/assets/logo192.png",
      logo: "/img/platforms/STMockup.jpg",
      image: "/img/platforms/STMockup.jpg",
      isLocalImg: false,
      downloadLink: "#",
    },
    {
      name: "InfinanceFx Trade IOS Trader",
      // logo: "https://portal.slingstrade.com/assets/logo192.png",
      logo: "/img/platforms/STMockup.jpg",
      image: "/img/platforms/STMockup.jpg",
      isLocalImg: false,
      downloadLink: "#",
    },
  ],
};
